<template>
  <d-table
      :show-pagination="false"
      :tablefields="tableFields"
      :items="[]"
      :is-busy="false"
      :totalRows="3"
      :empty-text="' '"
  >

  </d-table>
</template>

<script>
  export default {
    props: {
      count: {
        type: String,
        default: '0'
      }
    },
    computed: {
      alignCellsToLeft() {
        return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
      },
      tableFields() {
        return [
          {
            key: "iconLabel",
            iconClass: "",
            labelClass: "table-title-bold text-left",
            label: this.$t("views.marketing.report.resume-content.recipient-count.title") + ' ' + this.count,
            sortable: false
          },
        ];
      },
    },
  }
</script>
<style lang="scss" scoped>
/deep/ .dataTable thead th:last-child {
  text-align: left;
}
</style>
